<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-container
          v-if="detail !== null"
          style="
            padding: 0 0 20px 0;
            margin: auto;
            border-radius: 5px;
            background: white;
          "
        >
          <v-form
            ref="entryForm"
            @submit.prevent="submitOvertime()"
            lazy-validation
            :disabled="loading"
          >
            <v-card
              tile
              flat
              style="
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                position: sticky;
                top: 60px;
                background: white;
                z-index: 5;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
              >
                <v-toolbar-title class="overline" style="position: relative">
                  <p
                    :style="`font-size:${
                      wWidth > 780 ? '20px' : '3vw'
                    }; margin: 0;`"
                  >
                    Detail Payroll
                    <v-tooltip bottom color="grey">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          text
                          icon
                          color="grey darken-2"
                          @click="getDetail"
                        >
                          <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>refresh page</span>
                    </v-tooltip>
                  </p>
                </v-toolbar-title>
              </div>
              no. dokumen
              <div class="actionButton">
                <div v-if="permission.update_perm" class="d-flex">
                  <div v-if="isAllowApprove">
                    <v-btn
                      v-if="detail.status === 1"
                      @click.prevent="approveDocument"
                      type="button"
                      rounded
                      elevation="0"
                      color="green"
                      class="white--text font-weight-bold ma-2"
                      style="font-size: 12px"
                      :loading="loading"
                    >
                      Approve
                    </v-btn>
                    <!-- <v-dialog
                      v-if="detail.status === 1"
                      v-model="approveDialog"
                      width="500"
                      persistent
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          type="button"
                          rounded
                          elevation="0"
                          color="green"
                          class="white--text font-weight-bold ma-2"
                          style="font-size: 12px"
                          :loading="loading"
                        >
                          Approve
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Formulir Persetujuan
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-col
                            md="12"
                            style="padding: 0; position: relative; top: 20px"
                          >
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                                    margin: 0;
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: rgba(0, 0, 0, 0.5);
                                  "
                                >
                                  Catatan
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0">
                                <v-textarea
                                  outlined
                                  v-model="detail.confirmed_note"
                                />
                              </v-col>
                            </div>
                          </v-col>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="approveDialog = false"
                            :loading="loading"
                          >
                            Tutup
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click.prevent="approveDocument()"
                            color="primary"
                            type="button"
                            text
                            outlined
                            :loading="loading"
                          >
                            Approve
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog> -->

                    <v-dialog
                      v-if="detail.status === 1"
                      v-model="rejectDialog"
                      width="500"
                      persistent
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          type="button"
                          rounded
                          elevation="0"
                          color="red"
                          class="white--text font-weight-bold ma-2"
                          style="font-size: 12px"
                          :loading="loading"
                        >
                          Reject
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Formulir Penolakan
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-col
                            md="12"
                            style="padding: 0; position: relative; top: 20px"
                          >
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                                    margin: 0;
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: rgba(0, 0, 0, 0.5);
                                  "
                                >
                                  Alasan penolakan
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0">
                                <v-textarea
                                  outlined
                                  v-model="detail.reject_reason"
                                />
                              </v-col>
                            </div>
                          </v-col>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="rejectDialog = false"
                            :loading="loading"
                          >
                            Tutup
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click.prevent="rejectDocument()"
                            color="primary"
                            type="button"
                            text
                            outlined
                            :loading="loading"
                          >
                            Tolak dokumen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>

                  <div
                    v-if="
                      getUserProfile.level.find(
                        ({ id }) => id == appLevel.adminMaster
                      ) !== undefined
                    "
                  >
                    <v-btn
                      v-if="detail.status === 0"
                      @click.prevent="submitPayroll"
                      type="button"
                      rounded
                      elevation="0"
                      color="green"
                      class="white--text font-weight-bold ma-2"
                      style="font-size: 12px"
                      :loading="loading"
                    >
                      Submit
                    </v-btn>
                    <v-btn
                      v-if="detail.status === 3 || detail.status === 4"
                      type="button"
                      elevation="0"
                      color="primary"
                      class="white--text font-weight-bold ma-2"
                      style="font-size: 12px"
                      @click.prevent="setToDraft"
                      :loading="loading"
                      :disabled="loading"
                      rounded
                    >
                      Set to Draft
                    </v-btn>
                    <v-dialog
                      v-if="detail.status === 2"
                      v-model="cancelDialog"
                      width="500"
                      persistent
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          type="button"
                          rounded
                          elevation="0"
                          color="red"
                          class="white--text font-weight-bold ma-2"
                          style="font-size: 12px"
                          :loading="loading"
                        >
                          Cancel
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Formulir Pembatalan
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-col
                            md="12"
                            style="padding: 0; position: relative; top: 20px"
                          >
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                                    margin: 0;
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: rgba(0, 0, 0, 0.5);
                                  "
                                >
                                  Alasan pembatalan (HRD)
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0">
                                <v-textarea
                                  v-model="detail.cancel_reason"
                                  outlined
                                />
                              </v-col>
                            </div>
                          </v-col>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="cancelDialog = false"
                            :loading="loading"
                          >
                            Tutup
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click.prevent="cancelDocument()"
                            color="primary"
                            type="button"
                            text
                            outlined
                            :loading="loading"
                          >
                            Batalkan pengajuan
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>

                  <v-btn
                    type="button"
                    rounded
                    elevation="0"
                    color="black"
                    class="black--text font-weight-bold ma-2"
                    style="font-size: 12px"
                    @click.prevent="closeDetail"
                    :loading="loading"
                    outlined
                  >
                    <v-icon small>mdi-chevron-left</v-icon>
                    Kembali
                  </v-btn>
                </div>
              </div>
              <div
                class="d-flex justify-center align-center"
                :style="`position:absolute; right:0; top:52px; ${statusColor(
                  detail.status
                )} padding: 5px 30px; min-width: 200px;`"
              >
                <p
                  style="
                    margin: 0;
                    font-size: 16px;
                    font-weight: bolder;
                    text-transform: uppercase;
                  "
                >
                  {{ statusName(detail.status) }}
                </p>
              </div>
            </v-card>

            <v-card elevation="0">
              <v-toolbar-title
                style="padding: 0; text-align: center; height: 30px"
              >
                <!-- <p
              v-if="detail.request_no !== null"
              style="font-size: 20px; margin: 0; font-weight: bold; color:indigo;position:relative;"
            >
              {{ detail.request_no }}
            </p> -->

                <div
                  class="d-flex justify-start align-center"
                  style="position: absolute; right: 0; left: 0; top: -20px"
                >
                  <p style="font-size: 12px; margin: 0 15px">
                    Tgl. Buat :
                    {{ convertDate(detail.createdAt) }} |
                    {{ detail.created_by_name }}
                  </p>
                </div>
              </v-toolbar-title>
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. Dokumen
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        :value="detail.document_no"
                        append-icon=""
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Periode
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        :value="
                          monthName(detail.period_month) +
                          ' ' +
                          detail.period_year
                        "
                        append-icon=""
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Perusahaan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-text-field
                        dense
                        :value="detail.company_name"
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Template
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-text-field
                        dense
                        :value="
                          detail.payroll_template != null
                            ? detail.payroll_template.name
                            : '-'
                        "
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Total Gaji Bersih
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-text-field
                        dense
                        :value="
                          'Rp.' + priceConvert(detail.total_gaji_bersih) || 0
                        "
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Total Gaji Periode Lalu
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-text-field
                        dense
                        :value="
                          'Rp.' +
                            priceConvert(detail.total_gaji_periode_lalu) || 0
                        "
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <!-- <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Total PPh21
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-text-field
                        dense
                        :value="detail.total_pph21 || 0"
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col> -->
                <!-- <v-col cols="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Total Karyawan Berdasarkan Template
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-text-field
                        dense
                        :value="detail.employee_detail.length"
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Total Karyawan Tergenerate
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0; margin-top: 4px">
                      <v-text-field
                        dense
                        :value="result.length"
                        readonly
                      />
                    </v-col>
                  </div>
                </v-col> -->
              </v-row>
            </v-card>
            <v-card flat class="mx-auto" style="padding: 10px 25px">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="payrollHeaders"
                class="elevation-1"
                :items="result"
                :loading="tbLoading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:top>
                  <div
                    @click.stop
                    class="d-flex justify-content-between align-center"
                  >
                    <div
                      v-if="
                        permission.create_perm == 1 &&
                        permission.update_perm == 1
                      "
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold ml-5"
                        style="font-size: 12px"
                        @click="exportPph21"
                        :disabled="tbLoading"
                      >
                        Export pph21
                      </v-btn>
                      <v-btn
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold ml-5"
                        style="font-size: 12px"
                        @click="exportPayroll"
                        :disabled="tbLoading"
                      >
                        Export payroll
                      </v-btn>
                    </div>
                    <v-spacer></v-spacer>
                    <div style="width: 310px">
                      <v-text-field
                        v-model="paramAPI.keyword"
                        append-icon="mdi-magnify"
                        label="Cari nama"
                        class="mx-4"
                        style="margin-left: 10px"
                        @keyup.enter="searchEnter"
                      />
                    </div>
                  </div>
                  <div @click.stop class="ml-5 d-flex" style="font-size: 12px">
                    <p style="margin: 0">Total karyawan tergenerate:</p>
                    <span style="font-weight: bolder; margin-left: 5px">
                      <a href="#" @click="openEmployeeList">
                        {{ result.length }} /
                        {{ detail.employee_detail.length }}</a
                      ></span
                    >
                  </div>
                </template>
                <template v-slot:[`item.no`]="{ index }">
                  <div
                    style="
                      font-size: 14px;
                      width: 20px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                  </div>
                </template>
                <template v-slot:[`item.period_year`]="{ item }">
                  <div :style="`font-size:12px; width:30px; padding: 0;`">
                    {{ item.period_year }}
                  </div>
                </template>
                <template v-slot:[`item.period_month`]="{ item }">
                  <div :style="`font-size:12px; width:65px; padding: 0;`">
                    {{ monthName(item.period_month) }}
                  </div>
                </template>
                <template v-slot:[`item.period`]="{ item }">
                  <div :style="`font-size:12px; width:160px; padding: 0;`">
                    {{ convertDate(item.period_start_date).slice(0, 10) }} -
                    {{ convertDate(item.period_end_date).slice(0, 9) }}
                  </div>
                </template>
                <template v-slot:[`item.employee.name`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 210px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.employee.name }}
                  </div>
                </template>
                <template v-slot:[`item.employee.company_name`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 250px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.employee.company_name }}
                  </div>
                </template>
                <template v-slot:[`item.gaji_pokok`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.gaji_pokok) }}
                  </div>
                </template>
                <template v-slot:[`item.lembur`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.lembur) }}
                  </div>
                </template>
                <template v-slot:[`item.tunjangan_jabatan`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.tunjangan_jabatan) }}
                  </div>
                </template>
                <template v-slot:[`item.tunjangan_penempatan`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.tunjangan_penempatan) }}
                  </div>
                </template>
                <template v-slot:[`item.tunjangan_perumahan`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.tunjangan_perumahan) }}
                  </div>
                </template>
                <template v-slot:[`item.gaji_kotor`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.gaji_kotor) }}
                  </div>
                </template>
                <template v-slot:[`item.bpjs_tk_comp`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.bpjs_tk_comp) }}
                  </div>
                </template>
                <template v-slot:[`item.bpjs_k_comp`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.bpjs_k_comp) }}
                  </div>
                </template>
                <template v-slot:[`item.bpjs_jp_comp`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.bpjs_jp_comp) }}
                  </div>
                </template>
                <template v-slot:[`item.bpjs_tk_emp`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.bpjs_tk_emp) }}
                  </div>
                </template>
                <template v-slot:[`item.bpjs_k_emp`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.bpjs_k_emp) }}
                  </div>
                </template>
                <template v-slot:[`item.bpjs_jp_emp`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.bpjs_jp_emp) }}
                  </div>
                </template>
                <template v-slot:[`item.piutang`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.piutang) }}
                  </div>
                </template>
                <template v-slot:[`item.lain_lain`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.lain_lain) }}
                  </div>
                </template>
                <template v-slot:[`item.potongan_absensi`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.potongan_absensi) }}
                  </div>
                </template>
                <template v-slot:[`item.gaji_bersih`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.gaji_bersih) }}
                  </div>
                </template>
                <template v-slot:[`item.pph21`]="{ item }">
                  <div :style="`font-size:12px; width:60px; padding: 0;`">
                    {{ priceConvert(item.pph21) }}
                  </div>
                </template>
                <template v-slot:[`item.total_hari_kerja`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{
                      item.total_hari_kerja !== null ? item.total_hari_kerja : 0
                    }}
                  </div>
                </template>
                <template v-slot:[`item.thp_periode_lalu`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.thp_periode_lalu) }}
                  </div>
                </template>
                <template v-slot:[`item.bonus`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.bonus) }}
                  </div>
                </template>
                <template v-slot:[`item.thr`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.thr) }}
                  </div>
                </template>
                <template v-slot:[`item.cop`]="{ item }">
                  <div :style="`font-size:12px; width:80px; padding: 0;`">
                    {{ priceConvert(item.cop) }}
                  </div>
                </template>
                <template v-slot:[`item.employee.level`]="{ item }">
                  <div :style="`font-size:12px; width:70px; padding: 0;`">
                    {{ item.employee.level.name }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
            <v-divider> </v-divider>
            <v-card
              v-if="detail.approval_matrix !== undefined"
              flat
              class="mx-auto"
              style="padding: 10px 25px; margin-top: 1px; margin-bottom: 10px"
            >
              <v-toolbar-title class="overline">
                <p
                  style="
                    font-size: 16px;
                    margin: 0;
                    font-weight: bold;
                    color: indigo;
                    margin-bottom: 10px;
                  "
                >
                  Approval Matrix
                </p>
              </v-toolbar-title>
              <v-data-table
                v-if="detail.approval_matrix !== null"
                mobile-breakpoint="0"
                :headers="approvalHeaders"
                :items="detail.approval_matrix"
                class="approvalMatrix elevation-1 mx-auto"
                :items-per-page="itemsPerPage"
                :server-items-length="totalSequenceData"
                hide-default-footer
              >
                <template v-slot:[`item.approver_user`]="{ item }">
                  <div @click="detailApprover(item)" style="cursor: pointer">
                    <v-chip
                      label
                      small
                      style="
                        margin-right: 5px;
                        padding: 0 3px;
                        cursor: pointer;
                        border: 1px solid rgba(0, 0, 0, 0.5);
                      "
                      v-for="(item, index) in item.approver_user"
                      :key="index"
                    >
                      {{ item.user.name }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.approved`]="{ item }">
                  <div
                    v-for="(items, index) in item.approver_user"
                    :key="index"
                  >
                    <v-chip
                      v-if="items.status === 1"
                      label
                      small
                      style="
                        margin-right: 5px;
                        padding: 0 3px;
                        cursor: pointer;
                        border: 1px solid rgba(0, 0, 0, 0.5);
                      "
                    >
                      {{ items.user.name }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.rejected`]="{ item }">
                  <div
                    v-for="(items, index) in item.approver_user"
                    :key="index"
                  >
                    <v-chip
                      v-if="items.status === -1"
                      label
                      small
                      style="
                        margin-right: 5px;
                        padding: 0 3px;
                        cursor: pointer;
                        border: 1px solid rgba(0, 0, 0, 0.5);
                      "
                    >
                      {{ items.user.name }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 150px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.created_at }}
                  </div> </template
                ><template v-slot:[`item.updated_at`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 150px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.updated_at }}
                  </div>
                </template>
              </v-data-table>

              <div style="text-align: left; padding-left: 5px">
                <v-btn
                  v-if="
                    getUserProfile.level.find(
                      ({ id }) =>
                        id == appLevel.adminMaster ||
                        id == appLevel.adminSupport
                    ) !== undefined
                  "
                  color="grey"
                  tile
                  dark
                  small
                  dense
                  style="font-size: 10px; width: 100px"
                  @click="fetch"
                  :loading="loading"
                >
                  <span>Fetch Matrix</span>
                </v-btn>
              </div>
            </v-card>
          </v-form>

          <v-dialog v-model="employeeListDialog" width="800">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Daftar employee berdasarkan template payroll
                <v-btn
                  @click="closeEmployeeList"
                  color="error"
                  type="button"
                  text
                  outlined
                  icon
                  fab
                  small
                  style="position: absolute; top: 10px; right: 15px"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text class="d-flex justify-center align-center">
                <v-col
                  md="12"
                  style="padding: 0; position: relative; top: 20px"
                >
                  <v-data-table
                    mobile-breakpoint="0"
                    dense
                    height="50vh"
                    :headers="employeeListHeaders"
                    :items="detail.employee_detail"
                    class="elevation-1 mx-auto"
                    fixed-header
                    :items-per-page="100"
                    :footer-props="{
                      showFirstLastPage: true,
                      showCurrentPage: true,
                      itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                    }"
                  >
                    <template v-slot:[`item.nik`]="{ item }">
                      <div :style="`font-size:12px; width:120px; padding: 0;`">
                        {{ item.nik }}
                      </div>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <div :style="`font-size:12px; width:180px; padding: 0;`">
                        {{ item.name }}
                      </div>
                    </template>
                    <template v-slot:[`item.company_name`]="{ item }">
                      <div :style="`font-size:12px; width:180px; padding: 0;`">
                        {{ item.company_name }}
                      </div>
                    </template>
                    <template v-slot:[`item.level`]="{ item }">
                      <div :style="`font-size:12px; width:70px; padding: 0;`">
                        {{ item.level.name }}
                      </div>
                    </template>
                    <template v-slot:[`item.is_generated`]="{ item }">
                      <div :style="`font-size:12px; padding: 0;`">
                        <v-icon v-if="item.is_generated" x-small color="success"
                          >mdi-check-outline</v-icon
                        >
                        <v-icon v-else x-small color="error"
                          >mdi-close-outline</v-icon
                        >
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-card-text>

              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </v-container>
        <div
          v-else
          class="d-flex justify-center align-center"
          style="width: 100%; height: 80vh; position: relative"
        >
          <div
            style="
              font-size: 12px;
              position: absolute;
              top: 10px;
              left: 10px;
              z-index: 10;
            "
          >
            <v-btn
              type="button"
              rounded
              elevation="0"
              color="red"
              class="white--text font-weight-bold ma-2"
              @click.prevent="closeDetail"
              :loading="loading"
              outlined
            >
              <v-icon small>mdi-chevron-left</v-icon>
              Kembali
            </v-btn>
          </div>

          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 15px;
              left: 0;
              right: 0;
            "
          >
            <v-toolbar-title class="overline" style="position: relative">
              <p
                :style="`font-size:${
                  wWidth > 780 ? '20px' : '3vw'
                }; margin: 0;`"
              >
                Detail Payroll
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDetail"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </p>
            </v-toolbar-title>
          </div>
          <p>Getting data ready...</p>
        </div>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import XLSX from 'xlsx-js-style'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      config: require('../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoadingPage: true,
      dialog: false,
      approveDialog: false,
      rejectDialog: false,
      cancelDialog: false,
      loading: false,
      isEdit: false,
      detail: null,
      wWidth: window.innerWidth,
      rules: {
        nameRules: [],
        companyRules: [],
        positionRules: [],
        departmentRules: []
      },

      itemsPerPage: 10,
      totalSequenceData: 1,
      matrix: [],
      isAllowApprove: false,
      imgSrc: '',
      approvalHeaders: [
        { text: 'Sequence', value: 'sequence', align: 'left', width: '5%' },
        { text: 'Doc. No', value: 'document_no', align: 'left', width: '10%' },
        { text: 'Model', value: 'table_name', align: 'center', width: '10%' },
        { text: 'User', value: 'approver_user', align: 'center', width: '20%' },
        {
          text: 'Min. Approver',
          value: 'min_approver',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Approved by',
          value: 'approved',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Rejected by',
          value: 'rejected',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center',
          width: '15%'
        },
        {
          text: 'Update at',
          value: 'updated_at',
          align: 'center',
          width: '15%'
        }
      ],
      payrollHeaders: [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tahun',
          value: 'period_year',
          align: 'left',
          sortable: false
        },
        {
          text: 'Bulan',
          value: 'period_month',
          align: 'left',
          sortable: false
        },
        {
          text: 'Cut Off',
          value: 'period',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'NIP',
        //   value: 'employee.nik',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Nama Karyawan',
          value: 'employee.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'employee.company_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jabatan',
          value: 'employee.level',
          align: 'left',
          sortable: false
        },
        {
          text: 'Hari Kerja',
          value: 'total_hari_kerja',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gaji Pokok',
          value: 'gaji_pokok',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lembur',
          value: 'lembur',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tunjangan Jabatan',
          value: 'tunjangan_jabatan',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tunjangan Penempatan',
          value: 'tunjangan_penempatan',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tunjangan Perumahan',
          value: 'tunjangan_perumahan',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gaji Kotor',
          value: 'gaji_kotor',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Perusahaan BPJS TK',
          value: 'bpjs_tk_comp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Perusahaan BPJS JP',
          value: 'bpjs_jp_comp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Perusahaan BPJS K',
          value: 'bpjs_k_comp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Karyawan BPJS TK',
          value: 'bpjs_tk_emp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Karyawan BPJS JP',
          value: 'bpjs_jp_emp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Karyawan BPJS K',
          value: 'bpjs_k_emp',
          align: 'left',
          sortable: false
        },
        {
          text: 'Piutang',
          value: 'piutang',
          align: 'left',
          sortable: false
        },
        {
          text: 'COP',
          value: 'cop',
          align: 'left',
          sortable: false
        },
        {
          text: 'Bonus',
          value: 'bonus',
          align: 'left',
          sortable: false
        },
        {
          text: 'THR',
          value: 'thr',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lain-lain',
          value: 'lain_lain',
          align: 'left',
          sortable: false
        },
        {
          text: 'Potongan Absensi',
          value: 'potongan_absensi',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gaji Bersih',
          value: 'gaji_bersih',
          align: 'left',
          sortable: false
        },
        {
          text: 'pph21',
          value: 'pph21',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gaji Periode Lalu',
          value: 'thp_periode_lalu',
          align: 'left',
          sortable: false
        }
      ],
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 100,
        itemsPerPage: 10,
        sortBy: 'name',
        sortType: 'asc'
      },
      totalData: 0,
      tbLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: []
      },
      result: [],
      taxObject: null,
      countryCode: null,
      ptkpStatus: null,
      terPercentage: null,
      pkpPercentage: null,
      employeeListDialog: false,
      employeeListHeaders: [
        { text: '', value: 'is_generated', align: 'center' },
        { text: 'NIK', value: 'nik' },
        { text: 'Nama', value: 'name' },
        { text: 'Perusahaan', value: 'company_name' },
        { text: 'Jabatan', value: 'level' }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant', 'getLoginPayroll'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.hr.userLevel
    // this.appLevel.administrator =
    //   this.config.application.all.userLevel.administrator
    if (this.getLoginPayroll) {
      this.verifyAccessToken()
    } else {
      setTimeout(() => {
        this.$router.push('/hr/payroll')
      }, 100)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setLoginPayroll']),
    async verifyAccessToken() {
      this.isLoadingPage = true
      await axios
        .post(
          `${this.oAuthApi}otp/access_token/verify`,
          {},
          {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          }
        )
        .then((res) => {
          console.log('verify access token>>>', res)
          if (res.data.status_code === '00') {
            this.getDataMenu()
          } else {
            this.setLoginPayroll(null)
            setTimeout(() => {
              this.$router.push('/hr/payroll')
            }, 100)
          }

          this.isLoadingPage = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isLoadingPage = false
        })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId
              // ||
              // application.id === this.config.application.all.applicationId
            )
            if (this.userLevel !== null) {
              // if (
              //   this.getUserProfile.level.find(
              //     ({ id }) => id == this.appLevel.administrator
              //   ) !== undefined
              // ) {
              //   this.resetPermission({
              //     data: {
              //       read_perm: 1,
              //       create_perm: 1,
              //       update_perm: 1,
              //       delete_perm: 1
              //     }
              //   })
              // } else {
              this.checkMenuAccess([menuId, this.userLevel.id])
              // }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      await this.getDetail()
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    // imgDialog() {
    //   this.imgSrc = this.form.photo
    //   setTimeout(() => {
    //     this.dialog = true
    //   }, 300)
    // },
    // imgError() {
    //   this.imgSrc =
    //     'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    // },
    async getDetail() {
      this.isLoadingPage = true
      const url = `${this.hrsApi}employee/payroll_document/detail/${this.$route.params.id}`
      await axios
        .get(url, {
          headers: {
            'x-access-token': this.getLoginPayroll
          }
        })
        .then((res) => {
          console.log('Detail >>>>', res)
          if (res.data.status_code == '00') {
            setTimeout(() => {
              this.detail = res.data.data
              this.getPayrollList()
              this.authorityCheck()
            }, 100)
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          this.isLoadingPage = false
          return null
        })
        .catch((err) => {
          this.isLoadingPage = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return null
        })
    },
    searchEnter() {
      this.getPayrollList()
    },
    async getPayrollList() {
      this.tbLoading = true
      await this.initDataTable().then((data) => {
        if (data.status_code == '00') {
          setTimeout(() => {
            this.result = data.data
            this.totalData = data.total_record
            this.detail.total_gaji_bersih = data.data.reduce(
              (accumulator, current) => accumulator + current.gaji_bersih,
              0
            )
            this.detail.total_thp_periode_lalu = data.data.reduce(
              (accumulator, current) => accumulator + current.thp_periode_lalu,
              0
            )
            console.log('detail>>>>', this.detail)
            this.tbLoading = false
          }, 1000)
        } else {
          setTimeout(() => {
            this.result = []
            this.totalData = 0
            this.tbLoading = false
          }, 1000)
        }
      })
    },
    async initDataTable() {
      const url = `${this.hrsApi}employee/payroll/list?offset=${
        this.paramAPI.offset * this.paramAPI.limit
      }&limit=${this.paramAPI.limit}&payroll_doc_id=${
        this.detail != null ? this.detail.id : null
      }&keyword=${this.paramAPI.keyword}`

      //   `keyword=${
      //   this.paramAPI.keyword
      // }&period_month=${this.detail.period_month}&period_year=${
      //   this.detail.period_year
      //   }&company_id=${this.detail.company_id}
      // `
      return await new Promise((resolve) => {
        axios
          .get(url, {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      if (this.permission.read_perm == 1) {
        setTimeout(() => {
          this.$router.push(`/hr/payroll/slip/detail/${pItem.id}`)
        }, 100)
      }
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getPayrollList()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getPayrollList()
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    closeDetail() {
      // if (this.prevRoute !== undefined) {
      //   // if (this.prevRoute.path !== '/') {
      //   if (this.prevRoute.path === '/') {
      //   //   this.$router.push(this.prevRoute.path)
      //   // } else {
      //     this.$router.push('/hr/dashboard')
      //   }
      // } else {
      this.$router.push('/hr/payroll')
      // }
    },

    async authorityCheck() {
      if (this.detail !== null) {
        if (this.detail.status == 1) {
          // if (
          //   this.getUserProfile.level.find(({ id }) => id === '38') !== undefined
          // ) {

          console.log('authorityCheck>>>>')
          if (this.detail.approval_matrix !== null) {
            await axios
              .get(
                `${this.oAuthApi}approval_matrix/document/allow_user?document_id=${this.detail.approval_matrix[0].document_id}&application_id=21`
              )
              .then((res) => {
                console.log('Allowed Approve>>>', res)
                if (res.data.status_code === '00') {
                  if (res.data.is_allow_approve !== 0) {
                    this.isAllowApprove = true
                    return null
                  }
                }
                this.isAllowApprove = false
                return null
              })
              .catch((err) => {
                this.isAllowApprove = false
                console.log(err)
                return null
              })
          }
          // }
        }
      }
    },

    async exportPph21() {
      const period =
        this.detail.period_year + '-' + this.detail.period_month + '-' + '30'
      try {
        this.tbLoading = true
        await this.getTaxComponent()
        const d = new Date(period)
        const year = d.getFullYear()
        const month = d.getMonth()

        const worksheet1 = this.sheetRekap(year, month)
        const worksheet2 = await this.sheetPph21(d)

        const worksheet3 = await this.sheetPph26()
        const worksheet4 = await this.sheetObjekPajak()
        const worksheet5 = await this.sheetKodeNegara()
        const worksheet6 = await this.sheetPtkp()
        // const worksheet7 = await this.sheetTarif()

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Rekap') // sheet 1
        XLSX.utils.book_append_sheet(workbook, worksheet2, '21') // sheet 2
        XLSX.utils.book_append_sheet(workbook, worksheet3, '26') // sheet 3
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet4,
          'Ref Daftar Objek Pajak'
        ) // sheet 4
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet5,
          'Ref Daftar Kode Negara'
        ) // sheet 5
        XLSX.utils.book_append_sheet(workbook, worksheet6, 'Ref Daftar PTKP') // sheet 6
        XLSX.writeFile(workbook, `pph21_${year}_${month}.xlsx`)

        setTimeout(() => {
          this.tbLoading = false
        }, 3000)
      } catch (err) {
        console.log('error export>>>', err)
        this.tbLoading = false
      }
    },
    convertChangeWorkingDate(raw) {
      if (raw !== null) {
        // const arr = []
        // for (let i = 0; i < raw.length; i++) {
        const splitDate = raw.split('-')
        const y = splitDate[0]
        const m = splitDate[1].length === 2 ? splitDate[1] : '0' + splitDate[1]
        const d = splitDate[2].length === 2 ? splitDate[2] : '0' + splitDate[2]
        const newDate = `${d}/${m}/${y}`
        // arr.push(newDate)
        // }
        return newDate
      }
    },
    exportPayroll() {
      const selectedData = []
      if (this.result.length > 0) {
        this.tbLoading = true
        const res = this.result
        for (let i = 0; i < this.result.length; i++) {
          selectedData.push({
            no: i + 1,
            Tahun: res[i].period_year,
            Bulan: this.monthName(res[i].period_month),
            'Cut Off':
              this.convertDate(res[i].period_start_date).slice(0, 10) +
              ' - ' +
              this.convertDate(res[i].period_end_date).slice(0, 9),
            'Nama Karyawan': res[i].employee.name,
            Perusahaan: res[i].employee.company_name,
            // 'Hari Kerja':
            //   res[i].total_hari_kerja !== null ? res[i].total_hari_kerja : 0,
            'Gaji Pokok': res[i].gaji_pokok ?? 0,
            Lembur: res[i].lembur ?? 0,
            'Tunjangan Jabatan': res[i].tunjangan_jabatan ?? 0,
            'Tunjangan Penempatan': res[i].tunjangan_penempatan ?? 0,
            'Tunjangan Perumahan': res[i].tunjangan_perumahan ?? 0,
            'Gaji Kotor': res[i].gaji_kotor ?? 0,
            'TG.Perusahaan BPJS JP': res[i].bpjs_jp_comp ?? 0,
            'TG.Perusahaan BPJS K': res[i].bpjs_k_comp ?? 0,
            'TG.Perusahaan BPJS TK': res[i].bpjs_tk_comp ?? 0,
            'TG.Karyawan BPJS JP': res[i].bpjs_jp_emp ?? 0,
            'TG.Karyawan BPJS K': res[i].bpjs_k_emp ?? 0,
            'TG.Karyawan BPJS TK': res[i].bpjs_tk_emp ?? 0,
            Piutang: res[i].piutang ?? 0,
            'Lain-lain': res[i].lain_lain ?? 0,
            COP: res[i].cop ?? 0,
            Bonus: res[i].bonus ?? 0,
            THR: res[i].thr ?? 0,
            'Potongan Absensi': res[i].potongan_absensi ?? 0,
            'Gaji Bersih': res[i].gaji_bersih ?? 0,
            pph21: res[i].pph21 ?? 0,
            'Gaji Periode Lalu': res[i].thp_periode_lalu ?? 0
          })
        }
        this.payrollWorksheet(selectedData)
      }
    },
    payrollWorksheet(arrData) {
      var data = XLSX.utils.json_to_sheet(arrData)
      var range = XLSX.utils.decode_range(data['!ref'])
      var fCellName = XLSX.utils.encode_cell({ c: 0, r: range.e.r + 1 })
      for (let i = 0; i <= range.e.c; i++) {
        data[XLSX.utils.encode_cell({ c: i, r: 0 })].s = {
          font: {
            sz: 12,
            bold: true
          }
        }
      }
      var lCellName = XLSX.utils.encode_cell({ c: range.e.c, r: range.e.r + 1 })
      data['!ref'] = `A1:${lCellName}`
      data[fCellName] = {
        t: 's',
        v: 'TOTAL',
        s: {
          alignment: {
            horizontal: 'center'
          },
          font: {
            sz: 12,
            bold: true
          }
        }
      }
      data[XLSX.utils.encode_cell({ c: 6, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.gaji_pokok),
        //     0
        //   )
        // ),
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 6,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 6, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 7, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.lembur),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 7,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 7, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 8, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) =>
        //       accumulator + Number(current.tunjangan_jabatan),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 8,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 8, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 9, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) =>
        //       accumulator + Number(current.tunjangan_penempatan),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 9,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 9, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 10, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) =>
        //       accumulator + Number(current.tunjangan_perumahan),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 10,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 10, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 11, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.gaji_kotor),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 11,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 11, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 12, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) =>
        //       accumulator + Number(current.bpjs_jp_comp),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 12,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 12, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 13, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.bpjs_k_comp),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 13,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 13, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 14, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) =>
        //       accumulator + Number(current.bpjs_tk_comp),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 14,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 14, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 15, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.bpjs_jp_emp),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 15,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 15, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 16, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.bpjs_k_emp),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 16,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 16, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 17, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.bpjs_tk_emp),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 17,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 17, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 18, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.piutang),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 18,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 18, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 19, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.lain_lain),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 19,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 19, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 20, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.cop),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 20,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 20, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 21, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.bonus),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 21,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 21, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 22, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.thr),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 22,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 22, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 23, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) =>
        //       accumulator + Number(current.potongan_absensi),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 23,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 23, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 24, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.gaji_bersih),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 24,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 24, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 25, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) => accumulator + Number(current.pph21),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 25,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 25, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }
      data[XLSX.utils.encode_cell({ c: 26, r: range.e.r + 1 })] = {
        t: 's',
        // v: this.priceConvert(
        //   this.result.reduce(
        //     (accumulator, current) =>
        //       accumulator + Number(current.thp_periode_lalu),
        //     0
        //   )
        // )
        z: 'Rp#,##0.00',
        f: `SUM(${XLSX.utils.encode_cell({
          c: 26,
          r: 1
        })}:${XLSX.utils.encode_cell({ c: 26, r: range.e.r })})`,
        s: {
          alignment: {
            horizontal: 'right'
          },
          font: { sz: 12 }
        }
      }

      for (let i = 0; i <= range.e.r; i++) {
        if (i > 0) {
          for (let j = 0; j <= 26; j++) {
            if (j > 5) {
              data[XLSX.utils.encode_cell({ c: j, r: i })].z = 'Rp#,##0.00'
            }
          }
        }
      }
      const merge = [
        { s: { r: range.e.r + 1, c: 0 }, e: { r: range.e.r + 1, c: 5 } }
      ]
      data['!merges'] = merge
      this.downloadExcell(data)
    },
    downloadExcell(data) {
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(
        wb,
        `payroll_${this.detail.period_month}_${this.detail.period_year}.xlsx`
      )
      setTimeout(() => {
        this.tbLoading = false
      }, 3000)
    },
    sheetRekap(year, month) {
      var worksheet = {
        '!ref': 'A1:H5',
        B2: {
          t: 's',
          v: 'Tahun Pajak',
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 16 }
          }
        },
        B3: {
          t: 's',
          v: 'Jumlah Bukti Potong PPh Pasal 21',
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 13 }
          }
        },
        B4: {
          t: 's',
          v: 'Jumlah Bukti Potong PPh Pasal 26',
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 13 }
          }
        },
        D2: {
          t: 's',
          v: year,
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 20 }
          }
        },
        E2: {
          t: 's',
          v: 'Masa Pajak',
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 16 }
          }
        },
        G2: {
          t: 'n',
          v: month + 1,
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 20 }
          }
        },
        G3: {
          t: 'n',
          v: this.result.length,
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 20 }
          }
        },
        G4: {
          t: 'n',
          v: 0,
          s: {
            alignment: {
              horizontal: 'center'
            },
            font: { sz: 20 }
          }
        }
      }
      const merge = [
        { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
        { s: { r: 2, c: 1 }, e: { r: 2, c: 5 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 5 } }
      ]
      worksheet['!merges'] = merge
      worksheet['!rows'] = [{}, { hpt: 26 }, { hpt: 26 }, { hpt: 26 }]
      // set width for col 2
      worksheet['!cols'] = [
        { wch: 8 },
        { wch: 11 },
        { wch: 11 },
        { wch: 15 },
        { wch: 11 },
        { wch: 11 },
        { wch: 15 },
        { wch: 8 }
      ]
      return worksheet
    },
    sheetPph21(date) {
      const worksheetData = []
      for (let i = 0; i < this.result.length; i++) {
        let penerima_penghasilan = ''
        // let employee_status = ''
        let ptkp_status = ''
        let no_identitas = ''
        let no_npwp = ''
        let tax_object = ''
        let facility = 'N'
        let penghasilan_bruto = 0
        const res = this.result[i]

        if (
          this.result[i].employee.npwp_no !== null &&
          this.result[i].employee.npwp_no !== '' &&
          this.result[i].employee.npwp_no !== '-'
        ) {
          penerima_penghasilan = 'NPWP'
          if (this.result[i].employee.npwp_no !== null) {
            no_npwp = this.result[i].employee.npwp_no.replace(/[^\w\s]/gi, '')
          }
        } else {
          if (
            this.result[i].employee.no_identitas !== null &&
            this.result[i].employee.no_identitas !== '' &&
            this.result[i].employee.no_identitas !== '-'
          ) {
            penerima_penghasilan = 'NIK'
            if (this.result[i].employee.no_identitas !== null) {
              no_identitas = this.result[i].employee.no_identitas.replace(
                /[^\w\s]/gi,
                ''
              )
            }
          }
        }
        if (this.result[i].employee.ptkp_status !== null) {
          ptkp_status = this.result[i].employee.ptkp_status.name
        }
        // if (this.result[i].employee.employee_status !== null) {
        //   employee_status = this.result[i].employee.employee_status.name
        // }
        if (this.result[i].employee.tax_object !== null) {
          tax_object = this.result[i].employee.tax_object.code
        }
        if (this.result[i].employee.facility_id !== null) {
          if (this.result[i].employee.facility_id == 1) {
            facility = 'SKB'
          }
          if (this.result[i].employee.facility_id == 2) {
            facility = 'DTP'
          }
        }

        penghasilan_bruto =
          res.gaji_pokok +
          res.tunjangan_jabatan +
          res.lembur +
          res.tunjangan_penempatan +
          res.tunjangan_perumahan +
          res.bpjs_k_comp +
          res.bpjs_tk_comp +
          res.bonus +
          res.thr -
          res.potongan_absensi

        const obj = {
          No: i + 1,
          'Tgl Pemotongan (dd/MM/yyyy)': this.convertDate(date).slice(0, 9),
          'Penerima Penghasilan? (NPWP/NIK)': penerima_penghasilan,
          'NPWP (tanpa format/tanda baca)': no_npwp,
          'NIK (tanpa format/tanda baca)': no_identitas,
          'Nama Penerima Penghasilan Sesuai NIK': this.result[i].employee.name,
          'Alamat Penerima Penghasilan Sesuai NIK':
            this.result[i].employee.address !== '-'
              ? this.result[i].employee.address
              : '',
          'Kode Objek Pajak': tax_object,
          'Penandatangan Menggunakan? (NPWP/NIK)': 'NPWP',
          'NPWP Penandatangan (tanpa format/tanda baca)': '523796122311000',
          'NIK Penandatangan (tanpa format/tanda baca)': null,
          'Kode PTKP': ptkp_status.replace('PTKP ', ''),
          'Pegawai Harian? (Ya/Tidak)':
            tax_object === '21-100-03' ? 'Ya' : 'Tidak',
          'Menggunakan Gross Up? (Ya/Tidak)':
            this.result[i].company_id == 6 ? 'Tidak' : 'Ya',
          'Penghasilan Bruto': this.priceConvert(penghasilan_bruto),
          // this.result[i].pph21 !== null ? this.result[i].pph21 : 0,
          'Terdapat Akumulasi Penghasilan Bruto Sebelumnya? (Ya/Tidak)': null,
          'Akumulasi Penghasilan Bruto Sebelumnya': null,
          'Mendapatkan Fasilitas ? (N/SKB/DTP)': facility,
          'Nomor SKB/Nomor DTP':
            facility === 'SKB' ? '' : facility === 'DTP' ? '' : null
        }

        worksheetData.push(obj)
      }
      var worksheet = XLSX.utils.json_to_sheet(worksheetData, {
        skipHeader: false,
        origin: 'A2'
      })
      // set height for row 2
      worksheet['!rows'] = [{}, { hpt: 60 }]
      // set width for col 2
      worksheet['!cols'] = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 40 },
        { wch: 45 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 36 }
      ]
      // change sheetpph21 format type to text
      var range = XLSX.utils.decode_range(worksheet['!ref'])
      for (var r = range.s.r; r <= range.e.r; r++) {
        for (var c = range.s.c; c <= range.e.c; c++) {
          var cellName = XLSX.utils.encode_cell({ c: c, r: r })
          if (worksheet[cellName] !== undefined) {
            // set style to cell
            if (r == 1) {
              worksheet[cellName].s = {
                alignment: {
                  wrapText: true,
                  vertical: 'top',
                  horizontal: 'center'
                }
              }
            }
            if (r > 1) {
              worksheet[cellName].z = '@'
            }
          }
        }
      }
      return worksheet
    },
    sheetPph26() {
      const worksheetData = []
      for (let i = 0; i < 1; i++) {
        worksheetData.push({
          No: '',
          'Tgl Pemotongan (dd/MM/yyyy)': null,
          'TIN (dengan format/tanda baca)': null,
          'Nama Penerima Penghasilan': null,
          'Alamat Penerima Penghasilan': null,
          'No Paspor Penerima Penghasilan': null,
          'Kode Negara': null,
          'Penandatangan Menggunakan? (NPWP/NIK)': null,
          'NPWP Penandatangan (tanpa format/tanda baca)': null,
          'NIK Penandatangan (tanpa format/tanda baca)': null,
          'Penghasilan Bruto': null,
          'Mendapatkan Fasilitas ? (N/SKD)': null,
          'Nomor Tanda Terima SKD': null,
          'Tarif SKD': null
        })
      }

      var worksheet = XLSX.utils.json_to_sheet(worksheetData, {
        skipHeader: false,
        origin: 'A2'
      })
      // set height for row 2
      worksheet['!rows'] = [{}, { hpt: 60 }]
      // set width for col 2
      worksheet['!cols'] = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 28 },
        { wch: 20 }
      ]
      // change sheetpph26 format type to text
      var range = XLSX.utils.decode_range(worksheet['!ref'])
      for (var r = range.s.r; r <= range.e.r; r++) {
        for (var c = range.s.c; c <= range.e.c; c++) {
          var cellName = XLSX.utils.encode_cell({ c: c, r: r })
          if (worksheet[cellName] !== undefined) {
            // set style to cell
            if (r == 1) {
              worksheet[cellName].s = {
                alignment: {
                  wrapText: true,
                  vertical: 'top',
                  horizontal: 'center'
                }
              }
            }
            if (r > 1) {
              worksheet[cellName].z = '@'
            }
          }
        }
      }
      return worksheet
    },
    async sheetObjekPajak() {
      let worksheetData = [
        {
          'Kode Objek Pajak': '',
          'Nama Objek Pajak': '',
          KAP: '',
          KJS: '',
          Keterangan: ''
        }
      ]
      if (this.taxObject !== null) {
        const data = []
        for (let i = 0; i < this.taxObject.length; i++) {
          data.push({
            'Kode Objek Pajak': this.taxObject[i].code,
            'Nama Objek Pajak': this.taxObject[i].name,
            KAP: this.taxObject[i].kap,
            KJS: this.taxObject[i].kjs,
            Keterangan: this.taxObject[i].description
          })
        }
        worksheetData = data
      }
      var worksheet = XLSX.utils.json_to_sheet(worksheetData, {
        skipHeader: false,
        origin: 'B2'
      })
      worksheet['!cols'] = [
        { wch: 2 },
        { wch: 16 },
        { wch: 130 },
        { wch: 10 },
        { wch: 10 },
        { wch: 13 }
      ]
      var range = XLSX.utils.decode_range(worksheet['!ref'])
      for (var r = range.s.r; r <= range.e.r; r++) {
        for (var c = range.s.c; c <= range.e.c; c++) {
          var cellName = XLSX.utils.encode_cell({ c: c, r: r })
          if (worksheet[cellName] !== undefined) {
            // set style to cell
            if (r == 1) {
              worksheet[cellName].s = {
                font: {
                  bold: true
                }
              }
            }
          }
        }
      }
      return worksheet
    },
    async sheetKodeNegara() {
      let worksheetData = [{ KODE: '', 'Nama Negara': '' }]
      if (this.countryCode !== null) {
        const data = []
        for (let i = 0; i < this.countryCode.length; i++) {
          data.push({
            KODE: this.countryCode[i].code,
            'Nama Negara': this.countryCode[i].name
          })
        }
        worksheetData = data
      }
      var worksheet = XLSX.utils.json_to_sheet(worksheetData, {
        skipHeader: false,
        origin: 'B2'
      })
      worksheet['!cols'] = [{ wch: 2 }, { wch: 9 }, { wch: 35 }]

      var range = XLSX.utils.decode_range(worksheet['!ref'])
      for (var r = range.s.r; r <= range.e.r; r++) {
        for (var c = range.s.c; c <= range.e.c; c++) {
          var cellName = XLSX.utils.encode_cell({ c: c, r: r })
          if (worksheet[cellName] !== undefined) {
            // set style to cell
            if (r == 1) {
              worksheet[cellName].s = {
                font: {
                  bold: true
                }
              }
            }
          }
        }
      }
      return worksheet
    },
    async sheetPtkp() {
      let worksheetData = [
        {
          PTKP: '',
          Nominal: ''
        }
      ]
      if (this.ptkpStatus !== null) {
        const data = []
        for (let i = 0; i < this.ptkpStatus.length; i++) {
          if (this.ptkpStatus[i].ter_type_id !== null) {
            data.push({
              PTKP: this.ptkpStatus[i].name,
              Nominal: this.ptkpStatus[i].nominal
            })
          }
        }

        worksheetData = data
      }
      var worksheet = XLSX.utils.json_to_sheet(worksheetData, {
        skipHeader: false,
        origin: 'B2'
      })
      worksheet['!cols'] = [{ wch: 2 }, { wch: 15 }, { wch: 15 }]
      var range = XLSX.utils.decode_range(worksheet['!ref'])
      for (var r = range.s.r; r <= range.e.r; r++) {
        for (var c = range.s.c; c <= range.e.c; c++) {
          var cellName = XLSX.utils.encode_cell({ c: c, r: r })
          if (worksheet[cellName] !== undefined) {
            // set style to cell
            if (r == 1) {
              worksheet[cellName].s = {
                font: {
                  bold: true
                }
              }
            }
          }
        }
      }
      return worksheet
    },
    async getTaxComponent() {
      await this.getTaxObject()
      await this.getCountryCode()
      await this.getPtkpStatus()
      // await this.getTerPercentage()
      // await this.getPkpPercentage()
      return null
    },
    async getTaxObject() {
      await axios
        .get(
          `${this.hrsApi}master/universal/taxobject/list?keyword=&limit=1000&offset=0`
        )
        .then((res) => {
          if (res.data.status_code == '00') {
            this.taxObject = res.data.data
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    async getCountryCode() {
      await axios
        .get(
          `${this.hrsApi}master/universal/country/list?keyword=&limit=1000&offset=0`
        )
        .then((res) => {
          if (res.data.status_code == '00') {
            this.countryCode = res.data.data
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    async getPtkpStatus() {
      await axios
        .get(
          `${this.hrsApi}master/universal/ptkpstatus/list?keyword=&limit=1000&offset=0&order_by=ter_type_id&order_type=asc`
        )
        .then((res) => {
          if (res.data.status_code == '00') {
            this.ptkpStatus = res.data.data
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    detailApprover(item) {
      console.log(item)
    },
    async submitPayroll() {
      this.showMsgDialog(
        'question',
        'Submit document ini sekarang ?',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .post(`${this.hrsApi}employee/payroll_document/submit`, {
              id: this.$route.params.id
            })
            .then((res) => {
              this.loading = false
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDetail()
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'error',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('error', res.data.status_msg, false)
                }
              }
              return null
            })
            .catch((err) => {
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
              this.loading = false
              return null
            })
        }
      })
    },
    async setToDraft() {
      this.showMsgDialog(
        'question',
        'Kembalikan document ini menjadi DRAFT ?',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .post(`${this.hrsApi}employee/payroll_document/set_to_draft`, {
              id: this.$route.params.id
            })
            .then((res) => {
              this.loading = false
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDetail()
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'error',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('error', res.data.status_msg, false)
                }
              }
              return null
            })
            .catch((err) => {
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
              this.loading = false
              return null
            })
        }
      })
    },
    async approveDocument() {
      this.showMsgDialog(
        'question',
        'Approve dokumen ini sekarang ?',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .post(`${this.hrsApi}employee/payroll_document/approve`, {
              document_id: this.$route.params.id
            })
            .then((res) => {
              this.loading = false
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                // this.loadLeaveApprovalNeeds()
                this.getDetail()
                this.approveDialog = false
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'error',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('error', res.data.status_msg, false)
                }
              }
              return null
            })
            .catch((err) => {
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
              this.loading = false
              return null
            })
        }
      })
    },
    async rejectDocument() {
      if (
        this.detail.reject_reason == null ||
        this.detail.reject_reason == ''
      ) {
        this.showMsgDialog('warning', 'Kolom alasan harus diisi', false)
        return null
      }
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/payroll_document/reject`, {
          document_id: this.$route.params.id,
          reject_reason: this.detail.reject_reason
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // this.loadLeaveApprovalNeeds()
            this.getDetail()
            this.rejectDialog = false
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async cancelDocument() {
      if (
        this.detail.cancel_reason == null ||
        this.detail.cancel_reason == ''
      ) {
        this.showMsgDialog('warning', 'Kolom alasan harus diisi', false)
        return null
      }
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/payroll_document/cancel`, {
          id: this.$route.params.id,
          cancel_reason: this.detail.cancel_reason
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },

    fetch() {
      this.showMsgDialog(
        'warning',
        'Data approval saat ini akan dikosongkan apakah anda yakin ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          const form = {
            act: 'fetch_matrix',
            document_id: this.detail.id,
            document_no: this.detail.document_no,
            application_id: 21,
            table_name: 'tr_employeepayrolldocuments',
            // ecatalogue_fpb_category_item: this.detail.category_item,
            // department_id: this.detail.department.id,
            company_id: this.detail.company_id
          }
          this.loading = true
          axios
            .post(`${this.oAuthApi}approval_matrix/document/save`, form)
            .then((res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDetail()
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog('error', res.data.error_msg[0].msg, false)
                } else {
                  this.showMsgDialog('error', res.data.status_msg, false)
                }
              }
              this.loading = false
            })
            .catch((err) => {
              this.showMsgDialog(
                'error',
                err
                  ? 'Cek Otoritas error, Silahkan info ke Admin'
                  : 'Cek Otoritas error, Silahkan info ke Admin',
                false
              )
              this.loading = false
            })
        }
      })
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },
    statusName(val) {
      switch (val) {
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'APPROVED'
        case 4:
          return 'CANCELED'
        case 3:
          return 'REJECTED'
        default:
          break
      }
    },
    statusColor(val) {
      switch (val) {
        case 0:
          return 'color: black; background-color: #4e4e4e1f;'
        case 1:
          return 'color: rgb(138, 90, 0); background-color: rgba(255, 166, 0, 0.3);'
        case 2:
          return 'color: green; background-color: #4e4e4e1f;'
        case 3:
          return 'color: red; background-color: #4e4e4e1f;'
        case 4:
          return 'color: red; background-color: #4e4e4e1f;'
        default:
          return 'black'
      }
    },

    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    monthName(val) {
      switch (val) {
        case 1:
          return 'JANUARI'
        case 2:
          return 'FEBRUARI'
        case 3:
          return 'MARET'
        case 4:
          return 'APRIL'
        case 5:
          return 'MAI'
        case 6:
          return 'JUNI'
        case 7:
          return 'JULI'
        case 8:
          return 'AGUSTUS'
        case 9:
          return 'SEPTEMBER'
        case 10:
          return 'OKTOBER'
        case 11:
          return 'NOVEMBER'
        case 12:
          return 'DESEMBER'
      }
    },
    openEmployeeList() {
      // console.log(this.result)
      if (
        this.result != null &&
        this.detail != null &&
        this.detail.employee_detail != null
      ) {
        for (let i = 0; i < this.detail.employee_detail.length; i++) {
          // console.log(this.detail.employee_detail[i])
          if (
            this.result.find(
              ({ employee }) =>
                employee.nik == this.detail.employee_detail[i].nik
            ) !== undefined
          ) {
            this.detail.employee_detail[i].is_generated = true
          }
        }
        // console.log(this.detail.employee_detail)
        setTimeout(() => {
          this.employeeListDialog = true
        }, 300)
      }
    },
    closeEmployeeList() {
      setTimeout(() => {
        this.employeeListDialog = false
      }, 300)
    }
  }
}
</script>
<style lang="scss">
.actionButton {
  position: relative;
  display: flex;
  // width: 400px;
}
.approvalMatrix {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
}
@media (max-width: 766px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
  }
  .approvalMatrix {
    margin-bottom: 50px;
  }
}
</style>
